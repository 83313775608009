import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import {
  IFixed,
  ILocalFile
} from "src/types/local-files";

import { IArticleData } from "../components/articles-grid/articles-grid";
import {
  ColumnWidthWrapper
  // FullWidthColumnWrapper,
} from "../components/confines-wrappers";
import Footer from "../components/footer";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import HomeHero from "../components/home-hero";
import {
  breadCrumbSchema,
  localBusinessSchema,
  organizationSchema,
  websiteSchema
} from "../components/html-schema-snippets";
import Insights from "../components/insights";
import Layout from "../components/layout";
import Link from "../components/link";
import ProjectsGrid from "../components/projects-grid";
import StayInTheLoop from "../components/stay-in-the-loop";
import Wysiwyg from "../components/wysiwyg";
import { DynamicImageSrcString } from "../templates/utils/dynamicImage";
import getProfile from "../templates/utils/getProfile";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../theme/theme-helper";
import { IBlogArticleData } from "./latest";
import { IProjectData } from "./projects";

interface IProps {
  data: IHomeProps;
}

export interface IHomeProps {
  nodeHome: {
    title: string;
    created: string;
    changed: string;
    field_meta_description: string;
    field_hero_heading: string;
    field_about_us_who_: {
      processed: string;
    };
    field_about_us_w_: {
      processed: string;
    };
    field_hero_video_url: string;
    field_landing_page_links: IPageLandingLinks[];
    relationships: {
      field_home_insights: IBlogArticleData[];
      field_hero_image: ILocalFile<IFixed>;
      field_home_about_us_image: ILocalFile<IFixed>;
      field_mobile_hero_image: ILocalFile<IFixed>;
      field_home_latest_projects: IProjectData[];
    };
  };
}

interface IPageLandingLinks {
  title: string;
  uri: string;
}

const HomeContainer = styled<IProps, "div">("div")`
  .section-cta-container {
    padding-top: 6.8rem;
    width: 100%;

    p {
      padding-top: 0;
      text-align: center;
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
    }
  }

  .about-us-container {
    .about-us {
      position: relative;
      background-image: url(${props =>
    DynamicImageSrcString(
      props.data.nodeHome.relationships.field_home_about_us_image
    )});
      background-size: cover;
      background-position: center;

      .overlay {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
        opacity: 0.7;
      }

      .about-us-wrapper {
        position: relative;
        z-index: 3;

        .who,
        .what {
          h3 {
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
            font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "regular"
    })};
            line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
            letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
            text-transform: uppercase;
          }

          p {
            color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};

            &:first-of-type {
              padding-top: 2.4rem;
            }
          }
        }

        .who {
          p {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
            font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
            line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
          }
        }

        .what {
          padding-top: 4.2rem;

          p {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 375px) {
    .about-us-container {
      .about-us {
        .about-us-wrapper {
          .who {
            p {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
            }
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    .section-cta-container {
      padding-top: 8.8rem;
    }
  }

  @media (min-width: 568px) {
    .about-us-container {
      .about-us {
        .about-us-wrapper {
          .who {
            p {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
            }
          }

          .what {
            p {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .about-us-container {
      .about-us {
        .about-us-wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .who,
          .what {
            padding-top: 0;
            width: 50%;
          }

          .who {
            padding-right: 4%;

            p {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
            }
          }

          .what {
            padding-left: 4%;

            p {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
            }
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .section-cta-container {
      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
      }
    }

    .about-us-container {
      .about-us {
        .about-us-wrapper {
          .who {
            p {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
            }
          }

          .what {
            p {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .about-us-container {
      .about-us {
        .about-us-wrapper {
          .who {
            p {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
            }
          }

          .what {
            p {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
            }
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .section-cta-container {
      padding-top: 10.2rem;
    }
  }
`;

export default class IndexPage extends React.Component<IProps /* , IState */> {
  // public readonly state: IState;

  /**
   * Instantiates a new HomeHero component
   */
  constructor(props: Readonly<IProps>) {
    super(props);
  }

  /**
   * When the component mounts
   */
  public componentDidMount() {
    this.handleScroll();

    // Let the DOM load before attempting to triggering the script bellow
    setTimeout(() => {
      this.handleScroll();
    }, 80);

    setTimeout(() => {
      this.handleScroll();
    }, 200);
    // Add window scroll event listener
    window.addEventListener("scroll", () => this.handleScroll());
    // Add window resize event listener
    window.addEventListener("resize", () => this.handleScroll());
  }

  /**
   * When the component un-mounts
   */
  public componentWillUnmount() {
    // Remove window scroll event listener
    window.removeEventListener("scroll", () => this.handleScroll());
    // Remove window resize event listener
    window.addEventListener("resize", () => this.handleScroll());
  }

  /**
   * Render
   */
  public render() {
    const { data } = this.props;

    const breadcrumbsSchemaData = breadCrumbSchema([
      {
        name: "Home",
        path: "/"
      }
    ]);

    const schemaJson = [
      websiteSchema,
      organizationSchema,
      localBusinessSchema,
      breadcrumbsSchemaData
    ];

    return (
      <Layout>
        <>
          <HelmetWrapper
            title={data.nodeHome.title}
            description={data.nodeHome.field_meta_description}
            updatedAt={data.nodeHome.changed}
            canonicalPath="/"
          >
            <script type="application/ld+json">
              {JSON.stringify(schemaJson)}
            </script>
          </HelmetWrapper>

          <header
            role="banner"
            id="hero"
          >
            <Header
              isHome
              initialTheme="dark"
            />

            <HomeHero data={data} />
          </header>

          <main
            id="main-content"
            role="main"
          >
            <h1 className="off-screen">
              {data.nodeHome.title}
            </h1>

            <HomeContainer data={data}>
              <section className="projects-container content-container">
                <h2 className="off-screen">
                  Latest projects
                </h2>

                <ProjectsGrid
                  headingTag="h3"
                  projects={data.nodeHome.relationships.field_home_latest_projects}
                />

                <div className="section-cta-container">
                  <p className="projects-link">
                    <span className="off-screen">
                      Click here to view
                      {" "}
                    </span>

                    <Link
                      type="styled"
                      href="/projects"
                    >
                      More of our work
                    </Link>
                  </p>
                </div>
              </section>

              <section className="about-us-container">
                <h2 className="off-screen">
                  About us
                </h2>

                <div className="about-us content-container">
                  <div className="overlay" />

                  <ColumnWidthWrapper className="about-us-wrapper">
                    <div className="who">
                      <h3>
                        Devoted, passionate, fearless -
                      </h3>

                      <Wysiwyg
                        type="formatted"
                        content={data.nodeHome.field_about_us_who_.processed}
                      />
                    </div>

                    <div className="what">
                      <h3>
                        What?
                      </h3>

                      <Wysiwyg
                        type="formatted"
                        content={data.nodeHome.field_about_us_w_.processed}
                      />
                    </div>

                    <div className="section-cta-container">
                      <p className="about-us-link">
                        <span className="off-screen">
                          Click here to see how you would
                          {" "}
                        </span>

                        <Link
                          type="styled"
                          color="white"
                          href="/contact"
                        >
                          Work with us
                        </Link>
                      </p>
                    </div>
                  </ColumnWidthWrapper>
                </div>
              </section>

              <section className="latest-container">
                <h2 className="off-screen">
                  Latest posts
                </h2>

                <Insights
                  headingTag="h3"
                  title="Insights"
                  gradient="orangePink"
                  articles={data.nodeHome.relationships.field_home_insights.map(
                    post => {
                      const profile = getProfile(post.relationships.author);

                      const articleObj: IArticleData = {
                        created: post.created,
                        path: post.path.alias,
                        title: post.title,
                        author: profile.field_full_name,
                        cover: post.relationships.field_cover_image
                      };

                      return articleObj;
                    }
                  )}
                />
              </section>
            </HomeContainer>
          </main>

          <footer role="contentinfo">
            <StayInTheLoop />

            <Footer />
          </footer>
        </>
      </Layout>
    );
  }

  /**
   * Handle scroll
   */
  private handleScroll() {
    const logo = document.getElementById("logo");
    const logoHero = document.getElementById("logo-hero");
    const heading = document.getElementById("heading");
    let logoHeroVpData: ClientRect | DOMRect;
    let headingVpData: ClientRect | DOMRect;

    // If the following DOMs exists
    if (logo && logoHero && heading) {
      logoHeroVpData = logoHero.getBoundingClientRect();
      headingVpData = heading.getBoundingClientRect();

      // If the heading offset has past the window top
      if (headingVpData.top <= 0) {
        logo.className = "logo-container";

        // If the window width is greater than 768px
        if (window.innerWidth >= 768) {
          logo.style.top = "50%";
        } else {
          logo.style.top = "0%";
        }
      } else {
        logo.style.top = `${logoHeroVpData.top}px` || "0px";
        logo.className = "logo-container no-transform";
      }
    }
  }
}

export const query = graphql`
  query homePageQuery {
    nodeHome {
      created
      changed
      title
      field_meta_description
      field_hero_heading
      field_about_us_who_ {
        processed
      }
      field_about_us_w_ {
        processed
      }
      field_hero_video_url
      field_landing_page_links {
        title
        uri
      }
      relationships {
        field_home_insights {
          __typename
          ... on node__labs_article {
            title
            created
            path {
              alias
            }
            relationships {
              author: uid {
                relationships {
                  profile__team_profile {
                    ...getProfileData
                  }
                }
              }
              field_cover_image {
                ...getLocalFileData
              }
            }
          }
        }
        field_hero_image {
          localFile {
            publicURL
            childImageSharp {
              fixed(quality: 80, width: 1920, height: 700) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
        field_mobile_hero_image {
          localFile {
            publicURL
            childImageSharp {
              fixed(quality: 80, width: 768, height: 512) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
        field_home_about_us_image {
          localFile {
            publicURL
            childImageSharp {
              fixed(quality: 80, width: 1920, height: 721) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
        field_home_latest_projects {
          ...getProjectData
        }
      }
    }
  }
`;
