import * as React from "react";
import { ParallaxBanner } from "react-scroll-parallax";

import {
  IFluid,
  ILocalFile
} from "src/types/local-files";

import { IFieldProjectTag } from "../../pages/projects";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import DynamicHtmlTag from "../dynamic-html-tag/dynamic-html-tag";
import DynamicImage from "../dynamic-image";
import Link from "../link";

interface IProjectData {
  title: string;
  description: string;
  cover: ILocalFile<IFluid>;
  alt: string;
  tags?: IFieldProjectTag[];
  client: string;
  path: string;
  headingTag?: string;
}

const ProjectTeaserStyled = styled.article`
  overflow: hidden;

  .parallax-outer {
    .parallax-inner {
      > div {
        top: 0 !important;
        bottom: 0 !important;
      }
    }
  }

  .heading,
  .tags {
    position: absolute;
    z-index: 2;
    left: 0;

    li:first-child {
      padding-top: 0.4rem;
    }
  }

  .heading {
    top: 0;
    margin: 0;
    padding: 8% 8% 0 8%;

    .client,
    .description {
      display: block;
      color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
    }

    .client {
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_1"
    })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "normalized"
    })};
      letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
      text-transform: uppercase;
    }

    .description {
      padding-top: 1.4rem;
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
    }
  }

  .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    bottom: 0;
    margin: 0;
    padding: 0 8% 10% 8%;
    list-style: none;
    color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
    font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "small_2"
  })};
    font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
    line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};

    li {
      margin: 0;
      padding-right: 0.2em;

      &:last-child {
        padding-right: 0;
      }

      &:nth-last-child(1n + 2):after {
        content: ", ";
      }
    }
  }

  .link {
    display: block;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-indent: -99999rem;
  }

  .overlay {
    transition: 0.2s ease-in-out;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .image {
    position: relative;
    top: -100%;
    left: -100%;
  }

  &:hover {
    .overlay {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  @media (min-width: 768px) {
    .heading {
      .description {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
      }
    }

    .tags {
      padding-bottom: 12%;
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
    }
  }

  @media (min-width: 1024px) {
    .heading {
      padding-top: 9%;
    }
  }

  @media (min-width: 1280px) {
    .heading {
      .client {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};
      }

      .description {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_3"
    })};
      }
    }

    .tags {
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_2"
  })};
    }
  }

  @media (min-width: 1440px) {
    .heading {
      .client {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
      }

      .description {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
      }
    }
  }
`;

const ProjectTeaser = ({
  title,
  description,
  client,
  cover,
  alt,
  tags,
  path,
  headingTag
}: IProjectData) => {
  return (
    <ProjectTeaserStyled>
      <ParallaxBanner
        layers={[
          {
            amount: 0.1,
            children: <DynamicImage
              data={cover}
              alt={title}
                      />
          }
        ]}
        style={{ height: "82.8125%" }}
      >
        <header role="banner">
          <DynamicHtmlTag
            htmlTag={headingTag || "h2"}
            className="off-screen"
          >
            {title}
          </DynamicHtmlTag>

          <p className="heading">
            <span className="client">
              {client}
            </span>

            <span className="off-screen">
              {" "}
              -

              {" "}
            </span>

            <span className="description">
              {description}
            </span>
          </p>
        </header>

        <footer role="contentinfo">
          <ul className="tags">
            {tags &&
              tags.map((tag: IFieldProjectTag, i: number) => {
                return (
                  <li key={i}>
                    {tag.name}
                  </li>
                );
              })}
          </ul>
        </footer>

        <Link
          className="link"
          href={path}
          type="none"
        >
          {title}
        </Link>

        <span className="overlay" />

        <div className="image">
          <DynamicImage
            data={cover}
            alt={alt}
          />
        </div>
      </ParallaxBanner>
    </ProjectTeaserStyled>
  );
};

export default ProjectTeaser;
