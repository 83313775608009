import * as React from "react";

import { IProjectData } from "../../pages/projects";
import styled from "../../theme/styled";
import { FullWidthColumnWrapper } from "../confines-wrappers";
import ProjectTeaser from "../project-teaser";

interface IProps {
  projects: IProjectData[];
  headingTag?: string;
}

const ProjectGridWrapper = styled(FullWidthColumnWrapper)`
  transition: 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

export const ProjectGridItem = styled.div`
  padding-top: 2rem;
  width: 100%;
  max-width: 36rem;

  &:first-child {
    padding-top: 0;
  }

  @media (min-width: 600px) {
    padding-top: 4%;
    padding-right: 2%;
    width: 50%;
    max-width: none;

    &:nth-child(2) {
      padding-top: 0;
    }

    &:nth-child(2n) {
      padding-left: 2%;
      padding-right: 0;
    }
  }
`;

const ProjectsGrid = ({ projects, headingTag }: IProps) => {
  return (
    <ProjectGridWrapper style={{ display: "flex" }}>
      {projects ?
        projects.map((project: IProjectData, index: number) => (
          <ProjectGridItem key={`article-${index}`}>
            <ProjectTeaser
              title={project.title}
              client={project.field_project_client_name}
              description={project.field_project_cover_description}
              tags={project.relationships.field_project_tags}
              cover={project.relationships.field_cover_image}
              alt={project.field_cover_image.alt}
              path={project.path.alias}
              headingTag={headingTag}
            />
          </ProjectGridItem>
        )) :
        "Nothing to see here!"}
    </ProjectGridWrapper>
  );
};

export default ProjectsGrid;
